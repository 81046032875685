<template>
  <div class="container tournament-list-container">
    <div class="breadcrumb-container">
      <div class="breadcrumb">
        <div class="mt-1 row text-center">
          <div class="col-12 text-start">
            <router-link :to="{ name: 'Record' }" class="ashen-link">{{ $t("record.default.pageTitle") }}</router-link>
            <i class="fa fa-angle-right m-l-10 m-r-5"></i> <span>{{ $t("record.recordList.healthRecordList") }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="next-prev-container">
      <div class="next-prev font-13 m-t-20 m-b-25">
        <div class="row mt-1 mx-0 text-center">
          <div class="col text-start text-clamp-1">
            <i v-if="prevTournamentName" class="fas align-bottom fa-angle-left color-88 font-16"></i>
            <router-link :to="previous" class="ashen-link">
              <span class="ml-3 mx-2 font-weight-bold">{{ prevTournamentName }}</span>
            </router-link>
          </div>
          <div class="col text-end d-flex next-tournament">
            <router-link :to="next" class="ashen-link text-clamp-1">
              <span class="mr-3 mx-2 font-weight-bold">{{ nextTournamentName }}</span>
            </router-link>
            <i v-if="nextTournamentName" class="fas fa-angle-right mt-1 color-88 font-16"></i>
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-header">
        <img src="../assets/images/racket-gray.svg">
        <span class="mx-2">{{ tournamentName }}</span>
      </div>

      <div v-if="loading" class="card-body py-5">
        <DotLoader :type="'pulse'"></DotLoader>
      </div>

      <div v-else-if="!loading" class="card-body tournament-card-body">
        <ul v-if="isMobile()" class="list-group">
          <li v-for="record in response.data"
              v-if="response && response.data"
              class="list-group-item justify-content-between align-items-center">

            <div class="row mx-0 m-b-5">
              <div class="col px-0">
                <span v-html="showHealthStatus(record.healthStatus)"></span>
                <span class="w-auto m-l-15" v-html="showDate(record.date)"></span>
              </div>
              <div class="p-0 w-auto">
                <span @click="cta('date', record)" v-html="showCTA()"></span>
              </div>
            </div>
            <div v-show="record.heatStrokeScore !== null" class="row mx-0">
              <div class="p-r-20 w-auto">
                {{ $t("record.recordList.score", { 'score': record.heatStrokeScore }) }}
              </div>
            </div>

            <!--
            <div class="row mx-0">
              <div class="p-r-20 w-auto">
                <span v-html="showBodyTemp(record.bodyTemp)"></span>
              </div>
              <div class="col px-0 text-clamp-1">
                {{ record.remarks }}
              </div>
            </div>
            -->
          </li>
        </ul>
        <div v-else class="datatable">
          <Table
              :classes="'font-13 color-33 border-0 header-0'"
              :columns="columns"
              :getData="getData"
              :loading="loading"
              :response="response"
              :sortable="false"
              @cta="cta">
          </Table>
        </div>
      </div>
      <div v-else class="card-body">
        <p>
          {{ $t("record.noTournament.noCompetition") }}
        </p>
      </div>
    </div>
  </div>

</template>

<script>
import {datatable} from '@/constants'
import Common from '../assets/jsipclient/common'
import Helper from '../assets/jsipclient/helper'
import {mapGetters} from 'vuex'
import Table from '../components/datatable/Table'
import DotLoader from '../components/loaders/DotLoader'

export default {
  name: "UserRecord",
  components: {
    Table,
    DotLoader
  },
  props: {
    tournamentId: {type: [Number, String], default: null},
    defaultResponse: {type: Object, default: null},
    defaultRecordResponse: {type: Object, default: null}
  },
  data() {
    return {
      columns: datatable.columns.record,
      response: {},
      errors: [],
      loading: false,
      nextTournamentId: 5,
      nextTournamentName: "",
      prevTournamentId: null,
      prevTournamentName: null,
      tournamentName: ""
    }
  },
  computed: {
    ...mapGetters(['config']),
    previous() {
      return {
        name: "UserRecord",
        params: {
          tournamentId: this.prevTournamentId
        }
      }
    },
    next() {
      return {
        name: "UserRecord",
        params: {
          tournamentId: this.nextTournamentId
        }
      }
    },
  },
  mounted() {
    this.initialPageLoadHandler()
    window.scrollTo({top: 0, behavior: "auto"})
  },
  watch: {
    async $route(to, from) {
      // Called from back or next
      await this.initialPageLoadHandler()
    }
  },
  methods: {
    initialPageLoadHandler() {
      if (this.defaultResponse) {
        this.response = this.defaultResponse
      }


      Object.assign(this.columns[0], {
        format: (date) => {
          return Helper.showDate(date, 'YYYY/MM/DD')
        },
      })

      Object.assign(this.columns[1], {
        format: (healthStatus) => {
          return this.showHealthStatus(healthStatus)
        },
      })
      Object.assign(this.columns[2], {
        format: (heatStrokeScore) => {
          return this.showHeatStrokeScore(heatStrokeScore)
        },
      });
      Object.assign(this.columns[3], {
        format: (params) => {
          return Helper.showCTA()
        },
      });

      // Object.assign(this.columns[2], {
      //   format: (bodyTemp) => {
      //     return Helper.showBodyTemp(bodyTemp)
      //   },
      // })
      //
      // Object.assign(this.columns[4], {
      //   format: (params) => {
      //     return Helper.showCTA()
      //   },
      // });

      $(".tooltip").hide();
      this.getData();
    },
    isMobile() {
      return (new Helper).isMobile
    },
    showDate(date, format = 'YYYY/MM/DD') {
      return Helper.showDate(date, format)
    },
    showBodyTemp(bodyTemp) {
      return Helper.showBodyTemp(bodyTemp)
    },
    showHeatStrokeScore(score) {
      if (score === null) {
        return ''
      }
      return Helper.showHeatStrokeScore(this.$t("record.recordList.score", {'score': score}))
    },
    showCTA() {
      return Helper.showCTA()
    },
    showHealthStatus(healthStatus, extraCss = '') {
      // healthStatus:
      // 0/3: No Input / 未入力
      // -1/2: Abnormality / 異常あり
      // 1/5: Normality / 異常なし
      // 4: Acceptation / 大会承認済
      healthStatus = healthStatus === -1 ? 2 :
          healthStatus === 1 ? 5 :
              healthStatus === 0 ? 3 : healthStatus
      var statusText = ""
      switch (healthStatus) {
        case 2:
          statusText = this.$t("record.recordDetails.abnormality")
          break;
        case 3:
          statusText = this.$t("record.recordDetails.notEntered")
          break;
        case 5:
          statusText = this.$t("record.recordDetails.noAbnormality")
          break;
        default:
          break;
      }

      return Helper.showHealthStatus(healthStatus, extraCss, statusText)
    },
    showTournamentStatus(tournamentStatus, extraCss = '') {
      var statusText = ""
      switch (tournamentStatus) {
        case 1:
          statusText = this.$t("home.default.comingSoon")
          break;
        case 2:
          statusText = this.$t("home.default.duringPeriod")
          break;
        case 3:
          statusText = this.$t("home.default.end")
          break;
      }
      return Helper.showTournamentStatus(tournamentStatus, extraCss, statusText)
    },
    async getData() {
      const api = new IpApi.UserApi(Helper.apiConfig(this.config.token))
      this.loading = true
      try {
        const result = await api.usersUserIdRecordsGetAsync(this.config.userId, this.tournamentId)

        if (result) {
          this.tournamentName = result.tournamentName
          this.nextTournamentId = result.nextTournamentId
          this.nextTournamentName = result.nextTournamentName
          this.prevTournamentId = result.prevTournamentId
          this.prevTournamentName = result.prevTournamentName
          this.loading = false
          this.response = {data: result.items}
        }
      } catch (error) {
        const isTokenError = Common.isTokenError(error.response)
        if (isTokenError) {
          this.$router.push({name: 'Login'});

        } else {
          Common.handleAPIError(error)
        }
      } finally {
        this.loading = false
      }
    },
    getFormattedDate(date, format = 'YYYY/MM/DD') {
      return moment(date).format(format)
    },
    cta(key, row) {
      // console.log(key, row)
      this.$router.push({
        name: "UserRecordDetail", params: {
          tournamentId: Number(this.tournamentId),
          userId: this.config.userId,
          date: row.date,
        },
        query: {
          date: row.date
        }
      })

    },
  }
}
</script>

<style lang="scss" scoped>
@import "./src/assets/scss/tournaments";

.tournament-card-body {
  padding: 0;

  > .row:not(:last-child) {
    border-bottom: 1px solid #D3D8EA;
  }
}

@media only screen and (max-width: 576px) {
  .card {
    border-radius: 0;
  }
}

.list-group {
  &-item {
    padding: 15px 20px;
    border-width: 0 0 0 0;
  }

  &-item:not(:last-child) {
    border-width: 0 0 1px 0;
  }

}
</style>
<style lang="scss">
@import "./src/assets/scss/mixins";

.userrecord table {
  display: table;

  thead {
    display: none;
  }

  tr {
    th,
    td {
      &:nth-child(1) {
        width: 14.75%;
      }

      &:nth-child(2) {
        width: 16.5%;
      }

      &:last-child {
        text-align: right;
        padding-right: 36px;
      }

      @media only screen and (max-width: 1200px) {


      }
      @media only screen and (max-width: 1100px) {

      }
      @media only screen and (max-width: 1030px) {

      }
      @media only screen and (max-width: 994px) {

      }
    }
  }
}
</style>
